<notifier-container></notifier-container>

<form [formGroup]="registerForm">
  <div class="p-3">
    
    <!-- API Event Checkbox -->
    <div class="form-check form-switch fs-6" style="height: 20px;"> 
      <input 
        class="form-check-input" 
        type="checkbox" 
        role="switch" 
        id="apiEventCheckbox" 
        formControlName="apiEventCheckbox"
        [(ngModel)]="selected.apiEvent"
        (change)="apiEventChange($event)"
      >
      <label class="form-check-label" for="apiEventCheckbox">Configurar como API Event</label>
    </div>

    <!-- Id usuario muevo -->
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="token" class="col-form-label">* Id usuario muevo</label>
          <div class="input-group">
            <input 
              id="token"
              type="text"
              class="form-control"
              [(ngModel)]="selected.token"
              (focus)="focus$.next($event.target)"
              (click)="click$.next($event.target)"
              #instance="ngbTypeahead"
              [ngbTypeahead]="search"
              [resultFormatter]="formatter"
              [inputFormatter]="formatter"
              (selectItem)="cambiaToken($event)"
              formControlName="token"
              [ngClass]="{ 'is-invalid': submitted && f.token.errors }"
            />
            <button 
              *ngIf="token" 
              type="button" 
              class="btn btn-link btn-icon mr-2 mb-2" 
              (click)="token=null"
            >
              <i class="icon ion-ios-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Titulo -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">* Titulo</label>
        <ng-select 
          id="titulo" 
          formControlName="titulo" 
          [ngClass]="{ 'is-invalid': submitted && f.titulo.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.titulo"
        >
        </ng-select>
      </div>
    </div>

    <!-- Cuerpo -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">* Cuerpo</label>
        <ng-select 
          id="cuerpo" 
          formControlName="cuerpo" 
          [ngClass]="{ 'is-invalid': submitted && f.cuerpo.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.cuerpo"
        >
        </ng-select>
      </div>
    </div>

    <!-- DeepLink -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">DeepLink</label>
        <ng-select 
          id="deepLink" 
          formControlName="deepLink" 
          [ngClass]="{ 'is-invalid': submitted && f.deepLink.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.deepLink"
        >
        </ng-select>
      </div>
    </div>

    <!-- Id de campaña -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">Id de campaña</label>
        <ng-select 
          id="campaignId" 
          formControlName="campaignId" 
          [ngClass]="{ 'is-invalid': submitted && f.deepLink.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.campaignId"
        >
        </ng-select>
      </div>
    </div>

    <!-- Id de sub campaña -->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">Id de subcampaña</label>
        <ng-select 
          id="subcampaignId" 
          formControlName="subcampaignId" 
          [ngClass]="{ 'is-invalid': submitted && f.deepLink.errors }"
          [items]="dataDinamic"
          [addTag]="addTagFn"
          [hideSelected]="true"
          [multiple]="true"
          bindLabel="name"
          [(ngModel)]="selected.subcampaignId"
        >
        </ng-select>
      </div>
    </div>
    
  </div>
</form>

<ngx-spinner 
  bdColor="rgba(0, 0, 0, 0.8)" 
  size="medium" 
  color="#fff" 
  type="ball-beat" 
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>