import { Token } from "./token";

export class Payload{
    titulo: any[] = [];
    token: Token;
    cuerpo: any[] = [];
    deepLink: any[] = [];
    apiEvent: boolean;
    campaignId: any[] = [];
    subcampaignId: any[] = [];
}
